export const LI01 = require('./_4090324.jpg');
export const LI02 = require('./P1008432.jpg');
export const LI03 = require('./PA250137.jpg');
export const LI04 = require('./PA250142.jpg');
export const LI05 = require('./PA300059.jpg');

const lightpaintings = [{
    src: LI01,
    width: 3529,
    height: 2823
}, {
    src: LI02,
    width: 4402,
    height: 3522,
}, {
    src: LI03,
    width: 4006,
    height: 3205,
}, {
    src: LI04,
    width: 4084,
    height: 3267,
}, {
    src: LI05,
    width: 2110,
    height: 2638,
},
]
