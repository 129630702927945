export const W01 = require('./P1001892-2.jpg');
export const W02 = require('./P1007417-3.jpg');
export const W03 = require('./P1009977.jpg');
export const W04 = require('./P1010069.jpg');
export const W05 = require('./P1010150.jpg');

const wildlifes = [{
    src: W01,
    width: 4830,
    height: 3888
}, {
    src: W02,
    width: 3115,
    height: 2492,
}, {
    src: W03,
    width: 2799,
    height: 3499,
}, {
    src: W04,
    width: 3110,
    height: 3888,
}, {
    src: W05,
    width: 2543,
    height: 3179,
},
]
