import * as React from 'react';
import { Grid, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const BottomBar = () => {
    const navigate = useNavigate()

    return (
        <Grid container justifyContent='center'>
            <Grid item xs={6} style={{ textAlign: 'end', paddingRight: 8, paddingTop: 16 }}>
                <Link style={{ cursor: 'pointer', color: 'white' }} onClick={() => navigate('impressum')}>
                    Impressum
                </Link>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'start', paddingLeft: 8, paddingTop: 16 }}>
                <Link style={{ cursor: 'pointer', color: 'white' }} onClick={() => navigate('dataSecurity')}>
                    Datenschutz
                </Link>
            </Grid>
        </Grid>
    )
}

export default BottomBar
