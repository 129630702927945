import React from 'react';
import { Box } from "@mui/material";
import TopBar from "./TopBar";
import MainPage from "./MainPage";
import ImpressumPage from "./ImpressumPage";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import DatesecurityPage from "./DatasecurityPage";

const ContentContainer = () => {
    return (
        <BrowserRouter>
            <Box sx={{overflowY: 'hidden', height: '100vh'}}>
                <TopBar />
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="impressum" element={<ImpressumPage />} />
                    <Route path="dataSecurity" element={<DatesecurityPage />} />
                </Routes>
            </Box>
        </BrowserRouter>
    )
};

export default ContentContainer
