import * as React from 'react';
import { AppBar, Toolbar, Typography, Box, Menu, IconButton, MenuItem, Button } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import JRLogo from "../Media/JRLogo.png";
import { AnimatedSocialIcon } from "react-animated-social-icons";
import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-scroll'
import { useLocation } from 'react-router-dom'

const TopBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [activePage, setActivePage] = React.useState('banner');
    const location = useLocation()

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const pages = [
        'banner',
        'introduction',
        'portraits',
        'landscapes',
        'lightpainting',
        'wildlife',
        'creative',
        'social'
    ];

    return (
        <AppBar position='sticky' sx={{ height: '64px' }}>
            <Toolbar>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        PaperProps={{
                            sx: {
                                left: '0!important',
                            }
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        {pages.map((page, i) => (
                            <HashLink
                                to={`/#${page}`}
                                smooth
                                style={{
                                    textDecoration: 'none',
                                }}
                            >
                                {location?.pathname === '/'
                                    ? (
                                        <Link
                                            hashSpy={true}
                                            key={page}
                                            to={page}
                                            activeClass='active'
                                            spy={true}
                                            smooth={true}
                                            containerId='mainContainer'
                                            onSetActive={() => {
                                                setActivePage(page)
                                            }}
                                            onSetInactive={() => {
                                                setActivePage('')
                                            }}
                                            onSet
                                        >
                                            <MenuItem onClick={() => {
                                                handleCloseNavMenu()
                                            }}>
                                                <Typography
                                                    textAlign="left"
                                                    variant='overline'
                                                    sx={{
                                                        color: activePage === page ? '#D53231' : 'white',
                                                        display: 'block',
                                                        ':active': {
                                                            color: '#D53231'
                                                        },
                                                        ':focus': {
                                                            color: '#D53231'
                                                        }
                                                    }}
                                                >
                                                    {page}
                                                </Typography>
                                            </MenuItem>
                                        </Link>
                                    )
                                    : (
                                        <MenuItem onClick={() => {
                                            handleCloseNavMenu()
                                        }}>
                                            <Typography
                                                textAlign="center"
                                                variant='overline'
                                                sx={{
                                                    color: activePage === page ? '#D53231' : 'white',
                                                    display: 'block',
                                                    ':hover': {
                                                        color: '#D53231'
                                                    }
                                                }}
                                            >
                                                {page}
                                            </Typography>
                                        </MenuItem>
                                    )
                                }
                            </HashLink>
                        ))}
                    </Menu>
                </Box>
                <HashLink
                    to={`/#banner`}
                    smooth
                    style={{
                        textDecoration: 'none',
                    }}
                >
                    <img
                        src={JRLogo} style={{ height: '50px', marginRight: '16px', caretColor: 'rgba(0, 0, 0, 0)' }}
                    />
                </HashLink>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    JR Fotografie
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {pages.map((page, i) => (
                        <HashLink
                            to={`/#${page}`}
                            smooth
                            style={{
                                textDecoration: 'none',
                            }}
                            activeStyle={{ color: '#D53231' }}
                        >
                            <Button
                                onClick={() => {
                                    console.log('page: ', page)
                                    handleCloseNavMenu()
                                    setActivePage(page)
                                }}
                                sx={{
                                    my: 2,
                                    color: activePage === page ? '#D53231' : 'white',
                                    display: 'block',
                                    ':hover': {
                                        color: '#D53231'
                                    }
                                }}
                            >
                                {page}
                            </Button>
                        </HashLink>
                    ))}
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <AnimatedSocialIcon
                        brandName="instagram"
                        url="https://www.instagram.com/juergen.reiser.fotografie/"
                        animation="bounce"
                        defaultColor="#D1D1D1"
                        hoverColor="#D53231"
                        width="2em"
                        animationDuration={0.8}
                    />
                    <AnimatedSocialIcon
                        brandName="www"
                        url="https://www.model-kartei.de/portfolios/photographer/623761/jrgen-reiser/"
                        animation="bounce"
                        defaultColor="#D1D1D1"
                        hoverColor="#D53231"
                        width="2em"
                        animationDuration={0.8}
                    />
                </Box>
            </Toolbar>
        </AppBar>
    )
};

export default TopBar
