import {Divider, Grid, Typography, useTheme} from "@mui/material";
import Banner from "./Banner";
import Introduction from "./Introduction";
import CarouselWrapperFullscreen from "./CarouselWrapperFullscreen";
import * as portraits from "../Media/Portraits";
import * as landscapes from "../Media/Landscape";
import * as lightpaintings from "../Media/Lightpainting";
import * as wildlife from "../Media/Wildlife";
import * as kreativ from "../Media/Kreativ";
import SocialBar from "./SocialBar";
import BottomBar from "./BottomBar";
import React, {useEffect, useRef} from "react";
import createScrollSnap from "scroll-snap";

const MainPage = () => {
    const theme = useTheme()
    const containerRef = useRef()

    useEffect(() => {
        const snapDestinationY = window.innerHeight - 64
        createScrollSnap(containerRef?.current, {
            snapDestinationY: `${snapDestinationY}px`,
            threshold: 0.3,
            duration: 300,
            timeout: 300,
        })
    }, [])

    return (
        <Grid container sx={{ backgroundColor: theme.palette.primary.main, overflowY: 'scroll', overflowX: 'hidden', height: 'calc(100vh - 64px)'}} id='mainContainer' ref={containerRef}>
            <Grid id='banner' item xs={12} sx={{height: 'calc(100vh - 64px)'}}>
                <Banner />
            </Grid>
            <Grid id='introduction' item xs={12} sx={{height: 'calc(100vh - 64px)', scrollSnapAlign: 'end'}}>
                <Introduction />
            </Grid>
            <Grid item xs={12} sx={{height: '32px'}} id='portraits' >
                <Divider sx={{
                    '&::before': {
                        borderTop: 'solid rgba(109, 27, 38, 1)'
                    },
                    '&::after': {
                        borderTop: 'solid rgba(109, 27, 38, 1)'
                    }
                }}>
                    <Typography variant='h5' color='#D1D1D1'>
                        Portraits
                    </Typography>
                </Divider>
            </Grid>
            <Grid item xs={12} sx={{height: 'calc(100vh - 96px)'}}>
                <CarouselWrapperFullscreen images={Object.values(portraits)} slidesToShow={3} />
            </Grid>
            <Grid id='landscapes' item xs={12} sx={{height: '32px'}}>
                <Divider sx={{
                    '&::before': {
                        borderTop: 'solid rgba(109, 27, 38, 1)'
                    },
                    '&::after': {
                        borderTop: 'solid rgba(109, 27, 38, 1)'
                    }
                }}>
                    <Typography variant='h5' color='#D1D1D1'>
                        Landscapes
                    </Typography>
                </Divider>
            </Grid>
            <Grid item xs={12} sx={{height: 'calc(100vh - 96px)'}}>
                <CarouselWrapperFullscreen images={Object.values(landscapes)} slidesToShow={3} />
            </Grid>
            <Grid id='lightpainting' item xs={12} sx={{height: '32px'}}>
                <Divider sx={{
                    '&::before': {
                        borderTop: 'solid rgba(109, 27, 38, 1)'
                    },
                    '&::after': {
                        borderTop: 'solid rgba(109, 27, 38, 1)'
                    }
                }}>
                    <Typography variant='h5' color='#D1D1D1'>
                        Lightpainting
                    </Typography>
                </Divider>
            </Grid>
            <Grid item xs={12} sx={{height: 'calc(100vh - 96px)'}}>
                <CarouselWrapperFullscreen images={Object.values(lightpaintings)} slidesToShow={3} />
            </Grid>
            <Grid id='wildlife' item xs={12} sx={{height: '32px'}}>
                <Divider sx={{
                    '&::before': {
                        borderTop: 'solid rgba(109, 27, 38, 1)'
                    },
                    '&::after': {
                        borderTop: 'solid rgba(109, 27, 38, 1)'
                    }
                }}>
                    <Typography variant='h5' color='#D1D1D1'>
                        Wildlife
                    </Typography>
                </Divider>
            </Grid>
            <Grid item xs={12} sx={{height: 'calc(100vh - 96px)'}}>
                <CarouselWrapperFullscreen images={Object.values(wildlife)} slidesToShow={3} />
            </Grid>
            <Grid id='creative' item xs={12} sx={{height: '32px'}}>
                <Divider sx={{
                    '&::before': {
                        borderTop: 'solid rgba(109, 27, 38, 1)'
                    },
                    '&::after': {
                        borderTop: 'solid rgba(109, 27, 38, 1)'
                    }
                }}>
                    <Typography variant='h5' color='#D1D1D1'>
                        Creative
                    </Typography>
                </Divider>
            </Grid>
            <Grid item xs={12} sx={{height: 'calc(100vh - 96px)'}}>
                <CarouselWrapperFullscreen images={Object.values(kreativ)} slidesToShow={3} />
            </Grid>
            <Grid id='social' item xs={12} sx={{height: '30vh', padding: '16px 0'}}>
                <SocialBar />
            </Grid>
            <Grid item xs={12} sx={{height: '48px', padding: '16px 0'}}>
                <BottomBar />
            </Grid>
        </Grid>
    )
};

export default MainPage
