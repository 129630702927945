import * as React from 'react';
import {Typography, Grid} from "@mui/material";
import {AnimatedSocialIcon} from "react-animated-social-icons";

const SocialBar = () => {
    return (
        <Grid container justifyContent='center' alignItems='flex-start' direction='column' sx={{paddingLeft: '50vw'}}>
            <Grid item xs={12} sx={{paddingBottom: '16px', display: 'flex', alignItems: 'center'}}>
                <Typography variant='h5' color='#D1D1D1'>
                    Social Media
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{paddingBottom: '16px', display: 'flex', alignItems: 'center'}}>
                <AnimatedSocialIcon
                    brandName="instagram"
                    url="https://www.instagram.com/juergen.reiser.fotografie/"
                    animation="bounce"
                    defaultColor="#D1D1D1"
                    hoverColor="#D53231"
                    width="2em"
                    animationDuration={0.8}
                />
                <Typography variant='h5' color='rgba(109, 27, 38, 1)'>
                    Instagram; Portraits
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{paddingBottom: '16px', display: 'flex', alignItems: 'center'}}>
                <AnimatedSocialIcon
                    brandName="instagram"
                    url="https://www.instagram.com/juergen.reiser.fotografie/"
                    animation="bounce"
                    defaultColor="#D1D1D1"
                    hoverColor="#D53231"
                    width="2em"
                    animationDuration={0.8}
                />
                <Typography variant='h5' color='rgba(109, 27, 38, 1)'>
                    Instagram; Wildlife
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{paddingBottom: '16px', display: 'flex', alignItems: 'center'}}>
                <AnimatedSocialIcon
                    brandName="www"
                    url="https://www.model-kartei.de/portfolios/photographer/623761/jrgen-reiser/"
                    animation="bounce"
                    defaultColor="#D1D1D1"
                    hoverColor="#D53231"
                    width="2em"
                    animationDuration={0.8}
                />
                <Typography variant='h5' color='rgba(109, 27, 38, 1)'>
                    Model-Kartei
                </Typography>
            </Grid>
        </Grid>
    )
}

export default SocialBar
