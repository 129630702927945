export const P01 = require('./P01.jpg');
export const P02 = require('./P02.jpg');
export const P03 = require('./P03.jpg');
export const P04 = require('./P04.jpg');
export const P05 = require('./P05.jpg');
export const P06 = require('./P06.jpg');
export const P07 = require('./P07.jpg');
export const P08 = require('./P08.jpg');
export const P09 = require('./P09.jpg');
export const P10 = require('./P10.jpg');
export const P11 = require('./P11.jpg');
export const P12 = require('./P12.jpg');
export const P13 = require('./P13.jpg');



const portraits = [{
    src: P01,
    width: 3888,
    height: 4860
}, {
    src: P02,
    width: 3285,
    height: 4106,
}, {
    src: P03,
    width: 3428,
    height: 4285,
}, {
    src: P04,
    width: 3569,
    height: 4461,
}, {
    src: P05,
    width: 3034,
    height: 4045,
}, {
    src: P06,
    width: 2840,
    height: 3550,
}]
