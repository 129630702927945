export const LA01 = require('./_4030241.jpg');
export const LA02 = require('./_6160641.jpg');
export const LA03 = require('./_9050836 (1).jpg');
export const LA04 = require('./P1003438-2.jpg');
export const LA05 = require('./P1010140 (1).jpg');
export const LA06 = require('./P1010297.jpg');
export const LA07 = require('./P1090401.jpg');

const landscapes = [{
            src: LA01,
            width: 3627,
            height: 4535
        }, {
        src: LA02,
        width: 3665,
        height: 2932,
    }, {
        src: LA03,
        width: 5184,
        height: 2916,
    }, {
        src: LA04,
        width: 4638,
        height: 2609,
    }, {
        src: LA05,
        width: 4656,
        height: 3725,
    }, {
        src: LA06,
        width: 4860,
        height: 3888,
    }, {
        src: LA07,
        width: 2933,
        height: 3666,
    }
    ]

