import * as React from 'react';
import {Paper, Box} from "@mui/material";
import PropTypes from 'prop-types'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'

const CarouselWrapperFullscreen = ({images, slidesToShow}) => {

    const sliderSettings = slidesToShow > 1
    ? {
            className: slidesToShow > 1 && "center variable-width slider",
            centerMode: true,
            centerPadding: '30px',
            slidesToShow: 1,
            infinite: true,
            slidesToScroll: 1,
            speed: 500,
            variableWidth: true,
            swipeToSlide: true,
            arrows: true,
        }
        : {
        centerMode: true,
        slidesToShow: 1,
        infinite: true,
        slidesToScroll: 1,
        speed: 500,
        swipeToSlide: true,
    };

    return (
        <Paper elevation={4} sx={{ height: '100%', width: '100%'}}>
                <Box item sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'center'}}>
                    <Slider {...sliderSettings} style={{ width: '100%', height: '100%' }}>
                        {images.map(img => (
                            <Box sx={{height: 'calc(100vh - 98px)'}}>
                                <img
                                    src={img}
                                    style={{maxHeight: 'calc(100vh - 98px)', maxWidth: '125vw', margin: '0 16px', boxSizing: 'border-box', border: 'thin #fff solid'}}
                                />
                            </Box>
                        ))}
                    </Slider>
                </Box>
         </Paper>
    )
};

CarouselWrapperFullscreen.propTypes = {
    images: PropTypes.array,
    slidesToShow: PropTypes.number,
}

CarouselWrapperFullscreen.defaultProps = {
    slidesToShow: 1,
}

export default CarouselWrapperFullscreen
