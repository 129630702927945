import React from "react";
import './App.css';
import { ThemeProvider } from "@mui/material";
import { mainTheme } from './styling/MainTheme'
import ContentContainer from "./components/ContentContainer";

function App() {
  return (
    <ThemeProvider theme={mainTheme}>
        <ContentContainer />
    </ThemeProvider>
  );
}

export default App;
