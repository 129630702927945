import * as React from 'react';
import { Paper } from "@mui/material";
import background_sun from '../Media/Background_sun.jpg'
import JRLogo from "../Media/JRLogo.png";

const Banner = () => {
    return (
        <Paper sx={{
            backgroundImage: `url(${background_sun})`,
            backgroundSize: 'cover',
            width: '100%',
            height: '100%',
            textAlign: 'center',
        }}>
            <img src={JRLogo} style={{ height: '40vh', paddingTop: '64px', color: 'rgba(240, 240, 240, 0.3)', caretColor: 'rgba(0, 0, 0, 0)' }} />
        </Paper>
    )
};

export default Banner
