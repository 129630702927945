import * as React from 'react';
import {Paper, Typography, Grid} from "@mui/material";
import self from '../Media/Self/JR_self_01.jpg'
import self2 from '../Media/Self/JR_self_02.jpg'
import {AnimatedSocialIcon} from "react-animated-social-icons";
import useMediaQuery from "@mui/material/useMediaQuery";

const Introduction = () => {
    const desktop = useMediaQuery('(min-width:600px)');

    const firstText = (
        <Typography variant='h6' sx={{ p: { md: 2, xs: 1 }, flex: '1 1 auto', color: 'black' }}>
            Herzlich Willkommen auf meiner Seite.
            <br/><br/>
            Mein Name ist Jürgen und ich bin seit nun über 3 Jahren mit meiner Kamera unterwegs um Momente festzuhalten, kreative Ideen umzusetzen und auch immer wieder neues auszuprobieren.
            Seit diesem Jahr habe ich auch ein Gewerbe angemeldet.
            <br/><br/>
            Zu meinen Bereichen gehören bis jetzt:
        </Typography>
    )

    const secondText = (
        <Grid direction='row' container sx={{ p: { md: 2, xs: 1 }, color: 'black', width: '100%', paddingLeft: { md: '10%' } }}>
            <Grid item xs={10} sx={{ textAlign: 'left' }}>
                <Typography variant='h5' sx={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '100%'}} >
                    -Naturfotografie (Landschaft/Tiere/Makro)
                </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right' }}>
                <AnimatedSocialIcon
                    brandName="instagram"
                    url="https://www.instagram.com/j.r.__photography__/?hl=de"
                    animation="bounce"
                    defaultColor="green"
                    hoverColor="#D53231"
                    width="1.5rem"
                    animationDuration={0.8}
                    style={{
                        textAlign: 'right',
                        padding: 0,
                    }}
                />
            </Grid>
            <Grid item xs={10} sx={{ textAlign: 'left' }}>
                <Typography variant='h5' sx={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '100%'}}>
                    -Portraitfotografie (Outdoor/Studio)
                </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: 'right' }}>
                <AnimatedSocialIcon
                    brandName="instagram"
                    url="https://www.instagram.com/juergen.reiser.fotografie/"
                    animation="bounce"
                    defaultColor="#D1D1D1"
                    hoverColor="#D53231"
                    width="1.5rem"
                    animationDuration={0.8}
                    style={{
                        textAlign: 'right',
                        padding: 0,
                    }}
                />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'left' }}>
                <Typography variant='h5' sx={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '100%'}}>
                    -Nachtaufnahmen (Milchstraße/Lightpainting)
                </Typography>
            </Grid>
        </Grid>
    )

    const thirdText = (
        <Typography variant='h6' sx={{ p: { md: 2, xs: 1 }, color: 'black' }}>
            Gerne erweitere ich mit neuen Ideen mein Portfolio
            Wenn dir etwas gefällt und du mehr darüber wissen möchtest,
            oder falls du Interesse an einem Shooting hast, kontaktiere mich gerne.
            <br/><br/>
            Viel Spaß beim durchstöbern der Bilder auf meiner Seite.
        </Typography>
    )

    const firstImg = (
        <Grid xs={3} md={4} item maxHeight={{ xs: '100%', md: 'calc(100% - 32px)' }} sx={{ flex: '1 1 auto', textAlign: 'right', maxWidth: {xs: '27vw'} }}>
            <img src={self} style={{ maxHeight: '100%', maxWidth: '100%', boxSizing: 'border-box', border: 'thin #fff solid' }}/>
        </Grid>
    )

    const secondImg = (
        <Grid xs={3} md={4} item maxHeight={{ xs: '100%', md: 'calc(100% - 32px)' }} sx={{ flex: '1 1 auto', textAlign: 'left', maxWidth: {xs: '27vw'} }}>
            <img src={self2} style={{ maxHeight: '100%', maxWidth: '100%', boxSizing: 'border-box', border: 'thin #fff solid' }}/>
        </Grid>
    )

    const desktopLayout = (
        <Grid container sx={{ height: 'calc(100% - 32px)', width: {md: '100vw', xs: '100vw'}, p: {md: 3, xs: 1} }} justify='center' align='center'>
            <Grid item xs={8} md={4} height='calc(100% - 32px)' sx={{ flex: '1 1 auto' }}>
                {firstText}
                {secondText}
                {thirdText}
            </Grid>
            {firstImg}
            {secondImg}
        </Grid>
    )

    const mobileLayout = (
        <Grid container sx={{ height: '100%', width: '100vw', p: {md: 3, xs: 1} }} justify='center' align='center' direction='column'>
            <Grid item sx={{ flex: '1 1 auto', display: 'flex', height: '40%', maxWidth: '100%!important' }}>
                <Grid item xs={8}>
                    {firstText}
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {firstImg}
                </Grid>
            </Grid>
            <Grid item sx={{ flex: '1 1 auto', height: '20%', maxWidth: '100%!important'}}>
                {secondText}
            </Grid>
            <Grid item sx={{ flex: '1 1 auto', display: 'flex', height: '40%', maxWidth: '100%!important' }}>
                <Grid item xs={8}>
                    {thirdText}
                </Grid>
                <Grid item xs={4}>
                    {secondImg}
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <Paper sx={{
            backgroundColor: '#B1B1B1',
            width: '100%',
            height: '100%',
        }}>
            {desktop
            ? desktopLayout
            : mobileLayout}
        </Paper>
    )
};

export default Introduction
