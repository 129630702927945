import {createTheme} from '@mui/material';

export const mainTheme = createTheme({
    typography: {
        h5: {
            '@media (max-width: 1260px)': {
                fontSize: '1.2rem',
                lineHeight: 1.2,
            }
        },
        h6: {
            '@media (max-width: 1260px)': {
                fontSize: '1rem',
                lineHeight: 1.1,
            }
        }
    },
    palette: {
        type: 'light',
        primary: {
            main: '#33191E',
        },
        secondary: {
            main: '#f50057',
        },
        background: {
            default: '#D53326',
            // paper: '#35291e',
            paper: '#33191E'
        },
        text: {
            primary: '#f5f0f0',
            secondary: '#f5f0f0',
            disabled: '#f5f0f0',
            hint: '#f5f0f0',
        },
    },
    components: {
        MuiMenuItem: {
            styleOverrides: {
                ':hover': {
                    color: 'rgba(109, 27, 38, 1)'
                }
            }
        }
    }
});
